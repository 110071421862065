import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import Shuffle from 'shufflejs';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

	private shuffleInstance: Shuffle;

	@ViewChild('filter') private filter: ElementRef;
	@ViewChild('shuffleContainer') private shuffleContainer: ElementRef;

  ngOnInit() {}

  ngAfterViewInit() {
		this.shuffleInstance = new Shuffle(this.shuffleContainer.nativeElement, {
      itemSelector: '.list-item'
    });
    
    this.filterBySkill('all');
  }

  filterBySkill(skill) {
    document.querySelector('#filter li.active').classList.remove('active');
    document.querySelector('#filter li[data-group="' + skill + '"]').classList.add('active');

    this.shuffleInstance.filter((element: Element) => {
      return element.getAttribute('data-groups').includes(skill);
    });
  }

}