<!-- START: SERVICES -->
<section class="section section-dark section-services" id="services">
    <div class="container">
        <div class="section-head">
            <span>C'est un plaisir de travailler avec eux</span>
        </div>

        <div class="row">
            <div class="col-lg-12 mb-5">
                <p class="text-justify" style="    color: #bbb9b9;
                font-size: 14px;
                letter-spacing: 0.3px;">Lorsque je collabore sur un projet, je suis <strong>impliqué</strong> à 100% sur celui-ci. Je vais tout mettre en oeuvre pour qu'il fonctionne le mieux possible. Pour cela, je me rends <strong>disponible</strong>, je suis également <strong>à l'écoute</strong> de la demande et <strong>m'adapte</strong> en fonction des potentiels modifications ou imprévus. Si quelque chose vous tient à coeur, je ferai mon maximum pour que cela puisse vous satisfaire. Je sais également être force de proposition : orientation technique ou fonctionnelle, respect des règles et standards du web. Durant toute la durée du projet (de la prise de contact jusqu'au déploiement du site internet), je serai <strong>rigoureux</strong> notamment grâce aux points réguliers que nous pourrions faire ensemble. Au plaisir de collaborer ensemble.</p>
            </div>
        </div>

        <div class="section-head mt-2 mt-md-5 mb-3">
            <h2 class="text-center mb-3">Mes avis</h2>
        </div>
        
        <div class="row">
            <div class="col-lg-12">

                <swiper [config]="config">
                    <div class="swiper-slide single-testimonials">
                        <div class="single-testimonials-in mx-auto text-center">
                            <div class="single-testimonials-head p-5">
                                <p>"Cette mission s'est parfaitement déroulée. Les attentes sur les développements ont été respectées : très bonne communication (review en live, disponibilité par Slack et par mail) et des ajustements sur les demandes initiales ont été traitées. Nous avons d'ailleurs recontacté Ludovic pour une nouvelle mission."</p>
                            </div>	
                            <div class="single-testimonials-foot mx-auto mt-5">
                                <h4 class="mt-3 font-weight-bold">Adrien</h4>
                                <span class="mt-1 d-block">Source : Malt.fr</span>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide single-testimonials ">
                        <div class="single-testimonials-in mx-auto text-center">
                            <div class="single-testimonials-head p-5">
                                <p>"Meeting all expectations and very flexible if anything comes in the way. Recommended."</p>
                            </div>	
                            <div class="single-testimonials-foot mx-auto mt-5">
                                <h4 class="mt-3 font-weight-bold">Roman</h4>
                                <span class="mt-1 d-block">Source : Freelancer.com</span>
                            </div>
                        </div>
                    </div>

                    <div class="swiper-slide single-testimonials ">
                        <div class="single-testimonials-in mx-auto text-center">
                            <div class="single-testimonials-head p-5">
                                <p>"Super expérience avec Ludovic, très professionnel, réactif et très compétent. Je le recommande à tous vivement !"</p>
                            </div>	
                            <div class="single-testimonials-foot mx-auto mt-5">
                                <h4 class="mt-3 font-weight-bold">Ludovic</h4>
                                <span class="mt-1 d-block">Source : Malt.fr</span>
                            </div>
                        </div>
                    </div>

                    <div class="swiper-slide single-testimonials ">
                        <div class="single-testimonials-in mx-auto text-center">
                            <div class="single-testimonials-head p-5">
                                <p>"Travail, rapide, efficace, Malteur à votre écoute."</p>
                            </div>	
                            <div class="single-testimonials-foot mx-auto mt-5">
                                <h4 class="mt-3 font-weight-bold">Aurore</h4>
                                <span class="mt-1 d-block">Source : Malt.fr</span>
                            </div>
                        </div>
                    </div>

                    <div class="swiper-slide single-testimonials ">
                        <div class="single-testimonials-in mx-auto text-center">
                            <div class="single-testimonials-head p-5">
                                <p>"Ludovic est un super talent ! Il a réalisé avec brio et rapidité sa mission. La communication était facile, Ludovic est à l'écoute et très réactif.."</p>
                            </div>	
                            <div class="single-testimonials-foot mx-auto mt-5">
                                <h4 class="mt-3 font-weight-bold">Lisa</h4>
                                <span class="mt-1 d-block">Source : Malt.fr</span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="swiper-slide single-testimonials ">
                        <div class="single-testimonials-in mx-auto text-center">
                            <div class="single-testimonials-head p-5">
                                <p>"Super communication ! Tres competent et disponible. Toute ma confiance! Je recommande ++."</p>
                            </div>	
                            <div class="single-testimonials-foot mx-auto mt-5">
                                <h4 class="mt-3 font-weight-bold">Pierre-Xavier</h4>
                                <span class="mt-1 d-block">Source : Freelancer.com</span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="swiper-slide single-testimonials ">
                        <div class="single-testimonials-in mx-auto text-center">
                            <div class="single-testimonials-head p-5">
                                <p>"Ludovic a réalisé sa mission d'intégration d'une découpe de maquette Front dans Symfony 4 avec brio Je la résumerai en 3 mots : Disponibilté, réactivité et efficacité, merci à toi. Nous continuons notre collaboration."</p>
                            </div>	
                            <div class="single-testimonials-foot mx-auto mt-5">
                                <h4 class="mt-3 font-weight-bold">Ange</h4>
                                <span class="mt-1 d-block">Source : Malt.fr</span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="swiper-slide single-testimonials ">
                        <div class="single-testimonials-in mx-auto text-center">
                            <div class="single-testimonials-head p-5">
                                <p>"Je recommande a 100%, dépannage rapide et je suis ravi de travailler avec Ludovic."</p>
                            </div>	
                            <div class="single-testimonials-foot mx-auto mt-5">
                                <h4 class="mt-3 font-weight-bold">Walid</h4>
                                <span class="mt-1 d-block">Source : Malt.fr</span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="swiper-slide single-testimonials ">
                        <div class="single-testimonials-in mx-auto text-center">
                            <div class="single-testimonials-head p-5">
                                <p>"Ludovic a été d'une efficacité redoutable concernant la mission qui lui a été confié. Nous le recommandons sans hésiter et nous allons continuer de collaborer ensemble."</p>
                            </div>	
                            <div class="single-testimonials-foot mx-auto mt-5">
                                <h4 class="mt-3 font-weight-bold">Nicolas</h4>
                                <span class="mt-1 d-block">Source : Malt.fr</span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="swiper-slide single-testimonials ">
                        <div class="single-testimonials-in mx-auto text-center">
                            <div class="single-testimonials-head p-5">
                                <p>"Très bonne collaboration avec Ludovic pour la reprise en main d'un site sous Symfony 2 Sérieux et efficace dans sa prestation !"</p>
                            </div>	
                            <div class="single-testimonials-foot mx-auto mt-5">
                                <h4 class="mt-3 font-weight-bold">Quentin</h4>
                                <span class="mt-1 d-block">Source : Malt.fr</span>
                            </div>
                        </div>
                    </div>
                </swiper>

                <!-- <div class="testimony-nav">
                    <a [routerLink]="" class="swiper-button-next swiper-button-white"></a>
                    <a [routerLink]="" class="swiper-button-prev swiper-button-white"></a>
                </div> -->

            </div>
        </div>
    </div>
</section>
<!-- END: SERVICES -->