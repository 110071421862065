<!-- START: SERVICES -->
<section class="section section-dark section-services" id="services">
    <div class="container">
        <div class="section-head">
            <span>Mes compétences</span>
        </div>

        <div class="row mt-4">
            <div class="col-sm-6">
                <div class="services-list b-box  p-4 wow-none fadeInUp" data-wow-none-duration="1.5s" data-wow-none-delay=".2s">
                    <img alt="Front end - Illustration" src="assets/images/front-end.png">
                    <div class="services-list-icon">
                        <i class="lni lni-pallet"></i>

                        <span>01</span>
                    </div>
                    <div class="services-list-desc">
                        <h5 class="mb-0">Front-end</h5>
                        <p class=" mt-3">✅ HTML5 / CSS3 / Bootstrap / Materialize / Sass / Gulp / Webpack<br/>✅ Frameworks : Angular / React</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="services-list b-box  p-4 wow-none fadeInUp" data-wow-none-duration="1.5s" data-wow-none-delay=".3s">
                    <img alt="Back end - Illustration" src="assets/images/back-end.png">
                    <div class="services-list-icon">
                        <i class="lni lni-code-alt"></i>
                        <span>02</span>
                    </div>
                    <div class="services-list-desc">
                        <h5 class="mb-0">Back-end</h5>
                        <p class=" mt-3">✅ PHP / Javascript / SGBD : MySQL - Redis - SQLite - MongoDB<br/>✅ Frameworks : Symfony / Zend / Node.js / Ruby on Rails</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="services-list b-box  p-4 wow-none fadeInUp" data-wow-none-duration="1.5s">
                    <img alt="CMS - Illustration" src="assets/images/CMS.png">
                    <div class="services-list-icon">
                        <i class="lni lni-cog"></i>
                        <span>03</span>
                    </div>
                    <div class="services-list-desc">
                        <h5 class="mb-0">CMS</h5>
                        <p class=" mt-3">✅ Prestashop (développement de thèmes/plugins sur mesure)<br/>✅ Wordpress (développement de thèmes/plugins sur mesure)</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="services-list b-box  p-4 wow-none fadeInUp" data-wow-none-duration="1.5s" data-wow-none-delay=".3s">
                    <img alt="Outils - Illustration" src="assets/images/outils.png">
                    <div class="services-list-icon">
                        <i class="lni lni-invention"></i>
                        <span>04</span>
                    </div>
                    <div class="services-list-desc">
                        <h5 class="mb-0">Outils annexes utilisés</h5>
                        <p class=" mt-3">✅ DevOps : CI/CD, Docker, Ansible, Kubernetes<br/>✅ Divers outils : Linux Debian, Git, Redmine, Zendesk</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END: SERVICES -->

<!-- START: RESUME -->

<section class="section section-light section-resume pt-3" id="resume">
    <div class="container">

        <div class="section-head">
            <span>Mes qualifications</span>
        </div>

        <div class="section-resume-wrap">
            <div class="section-experience mb-5">
                <div class="row">
                    <div class="col-lg-3">
                        <h5 class="section-subHead mb-4 ">Mes expériences</h5>
                    </div>

                    <div class="col-lg-9">
                        <div class="Exper5DienceList pb-3">
                            
                            <div class="resume-list b-box p-4">
                                <div class="row align-items-center">
                                    <div class="col-3 col-md-3 pb-3 pb-md-0">
                                        Freelance
                                    </div>
                                    <div class="col-md-5 pb-2 pb-md-0">
                                        <h5 class="mb-3">Intégrateur / Développeur web</h5>
                                        <p>Vous pourrez, en cliquant <a class="text-underline" routerLink="/mes-realisations">ici</a>, voir quelques unes de mes réalisations.</p>
                                    </div>
                                    <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2017 - Present</h6></div>
                                    
                                </div>
                            </div>
                            <div class="resume-list b-box p-4">
                                <div class="row align-items-center">
                                    <div class="col-3 col-md-3 pb-3 pb-md-0">
                                        OpenClassrooms
                                    </div>
                                    <div class="col-md-5 pb-2 pb-md-0">
                                        <h5 class="mb-3">Formateur web</h5>
                                        <p>Je me dois d'être capable de concevoir et d'animer une formation respectant les attentes des apprenants et de l'organisme de formation. </p>
                                    </div>
                                    <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2020 - Present</h6></div>
                                    
                                </div>
                            </div>
                            <div class="resume-list b-box p-4">
                                <div class="row align-items-center">
                                    <div class="col-3 col-md-3 pb-3 pb-md-0">
                                        Formateur web
                                    </div>
                                    <div class="col-md-5 pb-2 pb-md-0">
                                        <h5 class="mb-3">Formateur web</h5>
                                        <p>Durant cette dernière année, j'ai eu la chance d'animer diverses formations sur l'agglomération de Clermont-Ferrand auprès de différents organismes :
                                        <br/>
                                        <br/>⭐ Formation Intégration web : 14 jours
                                        <br/>⭐ Formation Symfony / Twig : 5 jours
                                        <br/>⭐ Formation PHP avancée : 5 jours
                                        <br/>⭐ Formation WordPress : 3 jours
                                        <br/>⭐ Formation Prestashop : 3 jours
                                        <br/>⭐ Formation ElectronJS : 3 jours</p>
                                    </div>
                                    <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2018 - 2019</h6></div>
                                    
                                </div>
                            </div>
                            <div class="resume-list b-box p-4">
                                <div class="row align-items-center">
                                    <div class="col-3 col-md-3 pb-3 pb-md-0">
                                        Playsharp	
                                    </div>
                                    <div class="col-md-5 pb-2 pb-md-0">
                                        <h5 class="mb-3">Full Stack - Zend-Angular</h5>
                                        <p>Participation au développement d'une solution web permettant l'analyse détaillée des performances de sportifs professionnels.</p>
                                    </div>
                                    <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2015 - 2019</h6></div>
                                    
                                </div>
                            </div>
                            <div class="resume-list b-box p-4">
                                <div class="row align-items-center">
                                    <div class="col-3 col-md-3 pb-3 pb-md-0">
                                        SUH	
                                    </div>
                                    <div class="col-md-5 pb-2 pb-md-0">
                                        <h5 class="mb-3">Développeur web - Symfony</h5>
                                        <p>Développement et déploiement d'une application internet pour les membres du Service Université Handicap (SUH) sur le serveur de l'Université Blaise Pascal à Clermont-Ferrand.</p>
                                    </div>
                                    <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2014 - 2015</h6></div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section-education">
                <div class="row">
                    <div class="col-lg-3">
                        <h5 class="section-subHead mb-4"> Mes diplômes</h5>
                    </div>

                    <div class="col-lg-9">
                        <div class="EducationList">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="resume-list b-box p-4 mb-4">
                                        <div>
                                            <h5>Licence pro web</h5>
                                            <h6 class="badge badge-light d-inline-block my-3">2015 - 2016</h6>
                                            <p>Développement d'applications Internet/Intranet. (BAC +3)</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="resume-list b-box p-4 mb-4">
                                        <div>
                                            <h5>DUT Informatique</h5>
                                            <h6 class="badge badge-light d-inline-block my-3">2013 - 2015</h6>
                                            <p>Développement logiciels et d'applications web. (BAC +2)</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="resume-list b-box p-4 mb-4">
                                        <div>
                                            <h5>Baccalauréat S</h5>
                                            <h6 class="badge badge-light d-inline-block my-3">2011 - 2013</h6>
                                            <p>Lycée Lafayette à Brioude<br/>Option SVT</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>