import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
})
export class NavigationComponent implements OnInit {

  public routeUrl: String;

  @ViewChild("body") private parentRef: ElementRef<HTMLElement>;

  constructor(private router: Router, private el: ElementRef) { 
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.routeUrl = event.url;
      }
    });
  }

  ngOnInit(): void {
    
  }

  toggleNavbar(): void {
    if (this.el.nativeElement.parentElement.parentElement.classList.contains('aside-open')) {
      this.closeNavbar()
    } else {
      this.openNavbar()
    }
  }

  openNavbar(): void {
    this.el.nativeElement.parentElement.parentElement.classList.add('aside-open');
    this.el.nativeElement.parentElement.parentElement.classList.add('overflow-hidden')
    this.el.nativeElement.querySelector('.ham').classList.add('active');

    this.el.nativeElement.parentElement.querySelector('.page-wrapper').addEventListener('click', this.onClick.bind(this));
  }

  closeNavbar(): void {
    this.el.nativeElement.parentElement.parentElement.classList.remove('aside-open');
    this.el.nativeElement.parentElement.parentElement.classList.remove('overflow-hidden')
    this.el.nativeElement.querySelector('.ham').classList.remove('active');

    this.el.nativeElement.parentElement.querySelector('.page-wrapper').removeEventLister('click', this.onClick.bind(this));
  }

  onClick(): void {
    this.closeNavbar()
  }
}
