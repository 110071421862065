<!-- START: BLOG -->
<section class="section section-light">
    <div class="container">
        <div class="section-head">
            <span>Blog</span>
            <h2>Mes derniers articles</h2>
        </div>

        <div class="row">
            <div class="col-sm-12">
            <!-- <div class="col-md-6">
                <a href="blog-details.html" class="blog-list b-box d-block">
                    <div class="blog-list--img">
                        <img src="assets/images/blog-1.jpg" alt="Blog Image" class="img-fluid">
                        <div class="blog-list--details d-flex justify-content-center align-items-center">
                            <div class="blog-list--details-in">
                                <p class="blog-date text-white">02 Jan, 2020</p>
                                <span class="blog-cat">Design</span>
                            </div>
                        </div>
                    </div>
                    <div class="blog-list--desc p-3">
                        <h3>10 tips for drawing with coloured pencils</h3>
                        <p>Fusce eget urna ante. Donec massa velit, varius a accumsan ac, tempor iaculis massa. Sed placerat justo sed libero varius vulputate. Ut a mi tempus massa malesuada fermentum.</p>
                    </div>
                </a>	
            </div>
            <div class="col-md-6">
                <a href="blog-details.html" class="blog-list b-box d-block">
                    <div class="blog-list--img">
                        <img src="assets/images/blog-2.jpg" alt="Blog Image" class="img-fluid">
                        <div class="blog-list--details d-flex justify-content-center align-items-center">
                            <div class="blog-list--details-in">
                                <p class="blog-date text-white">02 Jan, 2020</p>
                                <span class="blog-cat">Design</span>
                            </div>
                        </div>
                    </div>
                    <div class="blog-list--desc p-3">
                        <h3>10 tips for drawing with coloured pencils</h3>
                        <p>Fusce eget urna ante. Donec massa velit, varius a accumsan ac, tempor iaculis massa. Sed placerat justo sed libero varius vulputate. Ut a mi tempus massa malesuada fermentum.</p>
                    </div>
                </a>	
            </div>
            <div class="col-md-6">
                <a href="blog-details.html" class="blog-list b-box d-block">
                    <div class="blog-list--img">
                        <img src="assets/images/blog-3.jpg" alt="Blog Image" class="img-fluid">
                        <div class="blog-list--details d-flex justify-content-center align-items-center">
                            <div class="blog-list--details-in">
                                <p class="blog-date text-white">02 Jan, 2020</p>
                                <span class="blog-cat">Design</span>
                            </div>
                        </div>
                    </div>
                    <div class="blog-list--desc p-3">
                        <h3>10 tips for drawing with coloured pencils</h3>
                        <p>Fusce eget urna ante. Donec massa velit, varius a accumsan ac, tempor iaculis massa. Sed placerat justo sed libero varius vulputate. Ut a mi tempus massa malesuada fermentum.</p>
                    </div>
                </a>	
            </div>
            <div class="col-md-6">
                <a href="blog-details.html" class="blog-list b-box d-block">
                    <div class="blog-list--img">
                        <img src="assets/images/p4-4.jpg" alt="Blog Image" class="img-fluid">
                        <div class="blog-list--details d-flex justify-content-center align-items-center">
                            <div class="blog-list--details-in">
                                <p class="blog-date text-white">02 Jan, 2020</p>
                                <span class="blog-cat">Design</span>
                            </div>
                        </div>
                    </div>
                    <div class="blog-list--desc p-3">
                        <h3>10 tips for drawing with coloured pencils</h3>
                        <p>Fusce eget urna ante. Donec massa velit, varius a accumsan ac, tempor iaculis massa. Sed placerat justo sed libero varius vulputate. Ut a mi tempus massa malesuada fermentum.</p>
                    </div>
                </a>	
            </div>
            <div class="col-md-6">
                <a href="blog-details.html" class="blog-list b-box d-block">
                    <div class="blog-list--img">
                        <img src="assets/images/p5-5.jpg" alt="Blog Image" class="img-fluid">
                        <div class="blog-list--details d-flex justify-content-center align-items-center">
                            <div class="blog-list--details-in">
                                <p class="blog-date text-white">02 Jan, 2020</p>
                                <span class="blog-cat">Design</span>
                            </div>
                        </div>
                    </div>
                    <div class="blog-list--desc p-3">
                        <h3>10 tips for drawing with coloured pencils</h3>
                        <p>Fusce eget urna ante. Donec massa velit, varius a accumsan ac, tempor iaculis massa. Sed placerat justo sed libero varius vulputate. Ut a mi tempus massa malesuada fermentum.</p>
                    </div>
                </a>	
            </div>
            <div class="col-md-6">
                <a href="blog-details.html" class="blog-list b-box d-block">
                    <div class="blog-list--img">
                        <img src="assets/images/p6-6.jpg" alt="Blog Image" class="img-fluid">
                        <div class="blog-list--details d-flex justify-content-center align-items-center">
                            <div class="blog-list--details-in">
                                <p class="blog-date text-white">02 Jan, 2020</p>
                                <span class="blog-cat">Design</span>
                            </div>
                        </div>
                    </div>
                    <div class="blog-list--desc p-3">
                        <h3>10 tips for drawing with coloured pencils</h3>
                        <p>Fusce eget urna ante. Donec massa velit, varius a accumsan ac, tempor iaculis massa. Sed placerat justo sed libero varius vulputate. Ut a mi tempus massa malesuada fermentum.</p>
                    </div>
                </a>	
            </div> -->

                En cours d'écriture...
            </div>
        </div>
        <div class="container">
            <nav class="mt-5 d-flex justify-content-center" aria-label="Page navigation">
                <ul class="pagination">
                    <li class="page-item">
                        <a class="page-link" [routerLink]="" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                            <span class="sr-only">Previous</span>
                        </a>
                    </li>
                    <li class="page-item active"><a class="page-link" href="#">1</a></li>
                    <!-- <li class="page-item active"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li> -->
                    <li class="page-item">
                        <a class="page-link" [routerLink]="" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                            <span class="sr-only">Next</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>		    				
    </div>
</section>
<!-- END: BLOG -->