import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  lat = 45.77080327284004;
  lng = 3.095663987419556;

  ngOnInit() {
  
  }


}
