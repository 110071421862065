<!-- START: CONTACT -->
<section class="section section-light section-contact" id="contact">
    <div class="container">
        <div class="section-head">
            <span>Vous avez des questions ?</span>
        </div>

        <div class="contact-map mt-5">
            <div class="row">
                <div class="col-lg-12">
                    <agm-map [latitude]="lat" [longitude]="lng" [zoom]="15">
                        <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                    </agm-map>
                </div>
            </div>
        </div>
    </div>

    <div class="contact-Otherdetails section-dark">
        <div class="container">
            <div class="contact-details mt-2">
                <h5 class="section-subHead pt-5 mb-4"> Entrons en contact</h5>
                <div class="row">
                    <div class="col-sm-6 col-lg-6">
                        <div class="contact-details--panel p-4 b-box text-center  wow-none fadeInUp" data-wow-none-duration="1.5s">
                            <div class="mb-3">
                                <i class="lni lni-mobile"></i>
                            </div>
                            <div class="mt-2">
                                <a href="tel:+33695948041" class="text-underline">+33695948041</a>
                                <p class="mt-2">Par téléphone</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-6">
                        <div class="contact-details--panel p-4 b-box text-center  wow-none fadeInUp" data-wow-none-duration="1.5s" data-wow-none-delay=".2s">
                            <div class="mb-3">
                                <i class="lni lni-envelope"></i>
                            </div>
                            <div class="mt-2">
                                <a href="mailto:ludo.rabat@gmail.com" class="text-underline">ludo.rabat@gmail.com</a>
                                <p class="mt-2">Par mail</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contact-form mt-5">

                <form method="post" name="contact-form" id="contactForm" #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)">
                    <h5 class="section-subHead mb-4"> Formulaire de contact</h5>
                    <div class="alert alert-success text-center" role="alert" *ngIf="isSend">
                        Le message a bien été envoyé. Vous receverez une réponse dans les plus brefs délais.
                    </div>
                    <div class="alert alert-danger text-center" role="alert" *ngIf="isSend === false">
                        Erreur lors de l'envoi du message. Veuillez vérifiez que tous les champs soient renseignés correctements.
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group mt-3">
                                <input name="name" id="name" ngModel #name="ngModel" type="text" class="form-control b-box" placeholder="Votre nom *" required>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group mt-3">
                                <input name="email" id="email" ngModel #email="ngModel" type="email" class="form-control b-box" placeholder="Votre mail *" required>
                            </div>
                        </div>                                
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group mt-3">
                                <input name="subject" type="text" ngModel #subject="ngModel" class="form-control b-box" id="subject" placeholder="Objet de la demande *" required>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group mt-3">
                                <textarea name="comments" id="comments" ngModel #comments="ngModel" rows="4" class="form-control b-box" placeholder="Votre message ..."></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 mt-4 text-center">
                            <input type="submit" id="submit" class="submitBnt btn btn-outline text-uppercase" value="Envoyer">
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</section>
<!-- END: CONTACT -->