import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(private http: HttpClient) { }

  lat = 45.77080327284004;
  lng = 3.095663987419556;

  isSend: Boolean = undefined;

  ngOnInit(): void {

  }

  onSubmit(contactForm: NgForm) {
    if (contactForm.valid) {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      this.http.post('https://formspree.io/mpzoodzl',
        contactForm.value,
        { 'headers': headers }).subscribe(
          response => {
            if (response['ok']) {
              this.isSend = true;
              contactForm.reset();
            } else {
              this.isSend = false;
            }
          }
        );
    } else {
      this.isSend = false;
      return;
    }
  }
  
}
