<app-navigation></app-navigation>


<!-- START: Preloader -->
<div id="preloader" [ngClass]="{'preloader': true, 'loaded': isLoading}">
    <div class="spinner-grow text-dark" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<!-- END: Preloader -->

<div class="page-wrapper">
    <router-outlet></router-outlet>

    <!--START: FOOTER-->
    <footer class="footer section section-light" *ngIf="routeUrl != '/'">
        <div class="container">
            <p class="copyright text-center mb-0">Copyright &copy; Ludovic Rabat 2021 | Tous droits réservés</p>
        </div>
    </footer>
    <!--END: FOOTER-->
</div>