<!-- START: Aside -->
<aside class="aside">
    <!-- START: NAVBAR -->

    <div class="nav-wrapper">

        <div class="navbar-toggler" (click)="toggleNavbar()">
            <svg class="ham hamRotate ham1" viewBox="0 0 100 100" width="40">
            <path class="line top" d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40" />
            <path class="line middle" d="m 30,50 h 40" />
            <path class="line bottom" d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40" />
            </svg>
        </div>

        <nav class="navbar text-center align-items-center justify-content-center">

            <div class="collapse navbar-collapse show" id="navbarCollapse">
                <div class="about-avatar mb-5">
                    <a routerLink="a-propos">
                        <img src="/assets/images/photo-de-profil.jpg" alt="Portrait - Ludovic Rabat" width="110" height="110" class="img-fluid mx-auto d-block shadow-sm rounded-full">
                    </a>
                    <div class="about-avatar-details mt-4">
                        <h2>Ludovic Rabat</h2>
                    </div>
                </div>
                <ul class="navbar-nav mx-auto">
                    <li class="nav-item {{ routeUrl == '/' ? 'active' : ''}}">
                        <a class="nav-link text-underline" routerLink=""><i class="lni lni lni-home"></i>Accueil</a>
                    </li>
                    <li class="nav-item {{ routeUrl == '/a-propos' ? 'active' : ''}}">
                        <a class="nav-link text-underline" routerLink="a-propos"><i class="lni lni-certificate"></i>A propos</a>
                    </li>
                    <li class="nav-item {{ routeUrl == '/mes-competences' ? 'active' : ''}}">
                        <a class="nav-link text-underline" routerLink="mes-competences"><i class="lni lni-graduation"></i>Compétences</a>
                    </li>
                    <li class="nav-item {{ routeUrl == '/mes-realisations' ? 'active' : ''}}">
                        <a class="nav-link text-underline" routerLink="mes-realisations"><i class="lni lni-construction-hammer"></i>Réalisations</a>
                    </li>
                    <li class="nav-item {{ routeUrl == '/blog' ? 'active' : ''}}">
                        <a class="nav-link text-underline" routerLink="blog"><i class="lni lni-pencil"></i>Blog</a>
                    </li>
                    <li class="nav-item {{ routeUrl == '/mes-avis' ? 'active' : ''}}">
                        <a class="nav-link text-underline" routerLink="mes-avis"><i class="lni lni-star"></i>Avis</a>
                    </li>
                    <li class="nav-item {{ routeUrl == '/contact' ? 'active' : ''}}">
                        <a class="nav-link text-underline" routerLink="contact"><i class="lni lni-phone"></i>Contact</a>
                    </li>
                </ul>
            </div>

            <div class="aside-footer">
                <ul class="list-unstyled list-inline">
                    <li class="list-inline-item"><a href="https://www.malt.fr/profile/ludovicrabat" title="Malt" target="_blank"><img src="./assets/images/malt.png" alt="Malt" width="16px" height="16px" style="height: 16px; width: auto; position: relative; top: -2px;" /></a>
                    <!-- <li class="list-inline-item"><a href="skype:live:ludo.rabat?chat"><i class="lni lni-skype"></i></a></li> -->
                    <li class="list-inline-item"><a href="https://www.linkedin.com/in/ludovic-rabat/" title="LinkedIn" target="_blank"><i class="lni lni-linkedin-original"></i></a></li>
                    <li class="list-inline-item"><a href="https://github.com/LudoWeb/" title="Github" target="_blank"><i class="lni lni-github"></i></a></li>
                    <li class="list-inline-item"><a href="https://www.freelancer.com/u/LudoProWeb" title="Freelancer" target="_blank"><img alt="Freelancer" src="./assets/images/freelancer.png" width="21px" height="16px" style="height: 16px; width: auto; position: relative; top: -2px;" /></a></li>
                    
                </ul>
            </div>
        </nav>
    </div>
    <!-- END: NAVBAR -->
</aside>
<!-- START: Aside -->