<!-- START: Portfolio -->

<section class="section section-light">
    <div class="container">
        <div class="section-head">
            <span>Portfolio</span>
            <h2>Mes réalisations</h2>
        </div>

        <p class="mb-5" style="    color: #bbb9b9;
        font-size: 13px;
        letter-spacing: 0.3px;">Dans un soucis de confidentialité, toutes mes réalisations ne sont pas consultables. Celles postées dans cette section ont fait l'objet d'une demande auprès du propriétaire de chaque site.</p>

        <ul #filter id="filter" class="filter mb-3 mb-md-5">
            <li class="active" (click)="filterBySkill('all')" data-group="all">Tous</li>
            <li class="" (click)="filterBySkill('symfony-twig')" data-group="symfony-twig">Symfony / Twig</li>
            <li class="" (click)="filterBySkill('prestashop')" data-group="prestashop">Prestashop</li>
            <li class="" (click)="filterBySkill('wordpress')" data-group="wordpress">Wordpress</li>
            <li class="" (click)="filterBySkill('zend')" data-group="zend">Zend</li>
            <li class="" (click)="filterBySkill('angular')" data-group="angular">Angular</li>
            <li class="" (click)="filterBySkill('react')" data-group="react">React</li>
            <li class="" (click)="filterBySkill('rails')" data-group="rails">Ruby on Rails</li>
        </ul>
    </div>

    <div class="container">
        <div #shuffleContainer class="list-items-container row my-gallery">

            <figure class="col-6 col-md-4 list-item mb-4 mb-md-5" data-itemprop="associatedMedia" data-groups="all symfony-twig">
                <a href="#" data-itemprop="contentUrl" data-size="1024x1024">
                    <img src="assets/images/acteurs-publics.jpg" data-itemprop="thumbnail" alt="Acteurs Publics" />
                </a>
                <figcaption data-itemprop="caption description">
                    <div class="caption-inner">
                        <h4 class="portfolio-item-title mt-3 mb-3">Acteurs Publics</h4>
                        <div class="portfolio-item-desc">
                            Media global spécialisé dans l’information (site à fort trafic).
                            Réalisation de la partie front-end et revue complète de l'interface du site en Symfony 4.<br/><br/>
                            <a class="text-underline" href="https://www.acteurspublics.fr/" target="_blank">Voir le site</a>
                        </div>
                    </div>
                </figcaption>
            </figure>

            <figure class="col-6 col-md-4 list-item mb-4 mb-md-5" data-itemprop="associatedMedia" data-groups="all prestashop">
                <a href="#" data-itemprop="contentUrl" data-size="1024x1024">
                    <img src="assets/images/bass-skateshop.png" data-itemprop="thumbnail" alt="Bass Skateshop" />
                </a>
                <figcaption data-itemprop="caption description">
                    <div class="caption-inner">
                        <h4 class="portfolio-item-title mt-3 mb-3">Bass Skateshop</h4>
                        <div class="portfolio-item-desc">
                            Réalisation d'un site e-commerce pour un magasin de skate au plein coeur de Clermont-Ferrand. Connexion avec un logiciel de caisse physique.<br/><br/>
                            <a class="text-underline" href="https://bass-skateshop.com/" target="_blank">Voir le site</a>
                        </div>
                    </div>
                </figcaption>
            </figure>

            <figure class="col-6 col-md-4 list-item mb-4 mb-md-5" data-itemprop="associatedMedia" data-groups="all symfony-twig react">
                <a href="#" data-itemprop="contentUrl" data-size="1024x1024">
                    <img src="assets/images/clubic.png" data-itemprop="thumbnail" alt="Clubic" />
                </a>
                <figcaption data-itemprop="caption description">
                    <div class="caption-inner">
                        <h4 class="portfolio-item-title mt-3 mb-3">Clubic</h4>
                        <div class="portfolio-item-desc">
                            Source d'information technologique (site à fort trafic).
                            Participation à la refonte du site internet côté front-end.<br/><br/>
                            <a class="text-underline" href="https://www.clubic.com/" target="_blank">Voir le site</a>
                        </div>
                    </div>
                </figcaption>
            </figure>

            <figure class="col-6 col-md-4 list-item mb-4 mb-md-5" data-itemprop="associatedMedia" data-groups="all prestashop">
                <a href="#" data-itemprop="contentUrl" data-size="1024x1024">
                    <img src="assets/images/fontenille-pataud.png" data-itemprop="thumbnail" alt="Fontenille Pataud" />
                </a>
                <figcaption data-itemprop="caption description">
                    <div class="caption-inner">
                        <h4 class="portfolio-item-title mt-3 mb-3">Fontenille Pataud</h4>
                        <div class="portfolio-item-desc">
                            Intégration d'une solution e-commerce sur-mesure sous Prestashop 1.7.4 en collaboration avec un designer.<br/><br/>
                            <a class="text-underline" href="https://fontenille-pataud.com/fr/" target="_blank">Voir le site</a>
                        </div>
                    </div>
                </figcaption>
            </figure>

            <figure class="col-6 col-md-4 list-item mb-4 mb-md-5" data-itemprop="associatedMedia" data-groups="all symfony-twig">
                <a href="#" data-itemprop="contentUrl" data-size="1024x1024">
                    <img src="assets/images/entre-nous.png" data-itemprop="thumbnail" alt="Acteurs Publics" />
                </a>
                <figcaption data-itemprop="caption description">
                    <div class="caption-inner">
                        <h4 class="portfolio-item-title mt-3 mb-3">Entre Nous</h4>
                        <div class="portfolio-item-desc">
                            Création complète du site internet du restaurant situé à Langeac (43) accompagné d'outils de gestion (Twig / PHP).<br/><br/>
                            <a class="text-underline" href="https://www.restaurantentrenous.fr/" target="_blank">Voir le site</a>
                        </div>
                    </div>
                </figcaption>
            </figure>

            <figure class="col-6 col-md-4 list-item mb-4 mb-md-5" data-itemprop="associatedMedia" data-groups="all prestashop">
                <a href="#" data-itemprop="contentUrl" data-size="1024x1024">
                    <img src="assets/images/sundeasy.png" data-itemprop="thumbnail" alt="Fontenille Pataud" />
                </a>
                <figcaption data-itemprop="caption description">
                    <div class="caption-inner">
                        <h4 class="portfolio-item-title mt-3 mb-3">Sundeasy</h4>
                        <div class="portfolio-item-desc">
                            Création du site e-commerce de A à Z sous Prestashop 1.7.6 (en cours de création).<br/><br/>
                            <a class="text-underline" href="https://sundeasy.fr/" target="_blank">Voir le site</a>
                        </div>
                    </div>
                </figcaption>
            </figure>

            <figure class="col-6 col-md-4 list-item mb-4 mb-md-5" data-itemprop="associatedMedia" data-groups="all zend">
                <a href="#" data-itemprop="contentUrl" data-size="1024x1024">
                    <img src="assets/images/ipa.png" data-itemprop="thumbnail" alt="IPA" />
                </a>
                <figcaption data-itemprop="caption description">
                    <div class="caption-inner">
                        <h4 class="portfolio-item-title mt-3 mb-3">IPA</h4>
                        <div class="portfolio-item-desc">
                            Intégration du site vitrine de la marque IPA à partir d'une maquette.<br/><br/>
                            <a class="text-underline" href="https://ipa-software.pro/" target="_blank">Voir le site</a>
                        </div>
                    </div>
                </figcaption>
            </figure>

            
            <figure class="col-6 col-md-4 list-item mb-4 mb-md-5" data-itemprop="associatedMedia" data-groups="all zend angular">
                <a href="#" data-itemprop="contentUrl" data-size="1024x1024">
                    <img src="assets/images/playsharp.png" data-itemprop="thumbnail" alt="Playsharp" />
                </a>
                <figcaption data-itemprop="caption description">
                    <div class="caption-inner">
                        <h4 class="portfolio-item-title mt-3 mb-3">Playsharp</h4>
                        <div class="portfolio-item-desc">
                            Refonte du site internet existant.<br/><br/>
                            <a class="text-underline" href="https://playsharp.pro/" target="_blank">Voir le site</a>
                        </div>
                    </div>
                </figcaption>
            </figure>

            <figure class="col-6 col-md-4 list-item mb-4 mb-md-5" data-itemprop="associatedMedia" data-groups="all zend">
                <a href="#" data-itemprop="contentUrl" data-size="1024x1024">
                    <img src="assets/images/medisharp.png" data-itemprop="thumbnail" alt="Medisharp" />
                </a>
                <figcaption data-itemprop="caption description">
                    <div class="caption-inner">
                        <h4 class="portfolio-item-title mt-3 mb-3">Medisharp</h4>
                        <div class="portfolio-item-desc">
                            Intégration du site vitrine de la solution médicale proposée par IPA.<br/><br/>
                            <a class="text-underline" href="https://mediweb.ipa-software.fr/" target="_blank">Voir le site</a>
                        </div>
                    </div>
                </figcaption>
            </figure>

            <figure class="col-6 col-md-4 list-item mb-4 mb-md-5" data-itemprop="associatedMedia" data-groups="all wordpress">
                <a href="#" data-itemprop="contentUrl" data-size="1024x1024">
                    <img src="assets/images/citizen-lights.png" data-itemprop="thumbnail" alt="Citizen Lights" />
                </a>
                <figcaption data-itemprop="caption description">
                    <div class="caption-inner">
                        <h4 class="portfolio-item-title mt-3 mb-3">Citizen Lights</h4>
                        <div class="portfolio-item-desc">
                            Création d'un site vitrine à partir d'un cahier des charges permettant de présenter et modifier facilement l'actualité du festival Belge.<br/><br/>
                            <a class="text-underline" href="https://citizenlights.be/" target="_blank">Voir le site</a>
                        </div>
                    </div>
                </figcaption>
            </figure>

            <figure class="col-6 col-md-4 list-item mb-4 mb-md-5" data-itemprop="associatedMedia" data-groups="all wordpress">
                <a href="#" data-itemprop="contentUrl" data-size="1024x1024">
                    <img src="assets/images/artisan-advisor.png" data-itemprop="thumbnail" alt="Artisan Advisor" />
                </a>
                <figcaption data-itemprop="caption description">
                    <div class="caption-inner">
                        <h4 class="portfolio-item-title mt-3 mb-3">Artisan Advisor</h4>
                        <div class="portfolio-item-desc">
                            Intégration globale d'une base de données de + de 30.000 artisans avec possibilité de prise de contrôle des artisans sur leurs propre fiches.<br/><br/>
                            <a class="text-underline" href="https://artisanadvisor.fr/" target="_blank">Voir le site</a>
                        </div>
                    </div>
                </figcaption>
            </figure>

            <figure class="col-6 col-md-4 list-item mb-4 mb-md-5" data-itemprop="associatedMedia" data-groups="all angular">
                <a href="#" data-itemprop="contentUrl" data-size="1024x1024">
                    <img src="assets/images/ludovic-rabat.png" data-itemprop="thumbnail" alt="Ludoivc Rabat" />
                </a>
                <figcaption data-itemprop="caption description">
                    <div class="caption-inner">
                        <h4 class="portfolio-item-title mt-3 mb-3">Ludovic Rabat</h4>
                        <div class="portfolio-item-desc">
                            Ce site a été réalisé en Angular 10.<br/><br/>
                        </div>
                    </div>
                </figcaption>
            </figure>

            <figure class="col-6 col-md-4 list-item mb-4 mb-md-5" data-itemprop="associatedMedia" data-groups="all react">
                <a href="#" data-itemprop="contentUrl" data-size="1024x1024">
                    <img src="assets/images/parlons-tv.png" data-itemprop="thumbnail" alt="Parlons TV" />
                </a>
                <figcaption data-itemprop="caption description">
                    <div class="caption-inner">
                        <h4 class="portfolio-item-title mt-3 mb-3">Parlons TV</h4>
                        <div class="portfolio-item-desc">
                            Création d'un réseau social permettant de parler via chat en temps réél (en cours)<br/><br/>
                        </div>
                    </div>
                </figcaption>
            </figure>

            <figure class="col-6 col-md-4 list-item mb-4 mb-md-5" data-itemprop="associatedMedia" data-groups="all rails">
                <a href="#" data-itemprop="contentUrl" data-size="1024x1024">
                    <img src="assets/images/globalty-investment.png" data-itemprop="thumbnail" alt="Globalty Investment" />
                </a>
                <figcaption data-itemprop="caption description">
                    <div class="caption-inner">
                        <h4 class="portfolio-item-title mt-3 mb-3">Globalty Investment - Blog</h4>
                        <div class="portfolio-item-desc">
                            Intégration de la partie front-end (sous Ruby on Rails) du blog en collaboration avec l'entreprise Soundlining.<br/><br/>
                            <a class="text-underline" href="https://www.globaltyinvestment.com/blog" target="_blank">Voir le site</a>
                        </div>
                    </div>
                </figcaption>
            </figure>

            <figure class="col-6 col-md-4 list-item mb-4 mb-md-5" data-itemprop="associatedMedia" data-groups="all rails">
                <a href="#" data-itemprop="contentUrl" data-size="1024x1024">
                    <img src="assets/images/macairet-chauffage.png" data-itemprop="thumbnail" alt="Macairet Chauffage" />
                </a>
                <figcaption data-itemprop="caption description">
                    <div class="caption-inner">
                        <h4 class="portfolio-item-title mt-3 mb-3">Macairet Chauffage</h4>
                        <div class="portfolio-item-desc">
                            Intégration de la pratie front-end (sous Ruby on Rails) en collaboration avec l'entreprise Soundlining.<br/><br/>
                            <a class="text-underline" href="https://macairet-chauffage.com/" target="_blank">Voir le site</a>
                        </div>
                    </div>
                </figcaption>
            </figure>
        </div>
    </div>
</section>

<!-- END: Portfolio -->