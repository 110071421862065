import { Component, ElementRef } from '@angular/core';
import {
  Router,
  // import as RouterEvent to avoid confusion with the DOM Event
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'portfolio';

  isLoading: Boolean = false;

  public routeUrl: String;

  constructor(private router: Router, private el: ElementRef) {
    this.router.events.subscribe((e : RouterEvent) => {
      
      if (e instanceof NavigationEnd) {
        this.routeUrl = e.url;
      }

       this.navigationInterceptor(e);
     })
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {

      this.el.nativeElement.parentElement.classList.remove('aside-open');
      this.el.nativeElement.parentElement.classList.remove('overflow-hidden')
      this.el.nativeElement.querySelector('.ham').classList.remove('active');

      setTimeout(() => {
        this.isLoading = true
     }, 400);
    }

    if (event instanceof NavigationEnd) {
      this.isLoading = false
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.isLoading = false
    }
    if (event instanceof NavigationError) {
      this.isLoading = false
    }
  }
}
