<!-- START: ABOUT -->
<section class="section section-about" id="about">
    <div class="container">
        <div class="section-head">
            <span>Faisons connaissance</span>
            <h2>Qui suis-je ?</h2>
        </div>

        <div class="row justify-content-center align-items-center">
            <div class="col-md-5">
                <img class="about-img img-fluid wow-none fadeInUp" data-wow-none-duration="1s" src="/assets/images/my-portrait.jpg" alt="Mon portrait">
            </div>
            <div class="col-md-7 about-ludovic">
                <div class="about-desc wow-none fadeInUp" data-wow-none-duration="1s"  data-wow-none-delay="500ms">
                    <div class="about-desc-content">
                        <p class="font-weight-light my-3">🚀 Intégrateur et développeur web, mon objectif est de vous accompagner pour la réalisation de votre site internet, de la conception jusqu'à la mise en ligne.</p>
                        <p class="font-weight-light my-3">🎯 J'exerce depuis plus de 5 ans et me suis spécialisé sur deux langages principaux :<br/>PHP et JavaScript.</p>
                        <p class="font-weight-light my-3">🤝 Passionné avant tout, je suis en perpétuelle recherche de nouveaux défis à relever.</p>
                        <p class="font-weight-light my-3">💻 Découvrez, en naviguant sur ce site, mon univers et mes différentes compétences.</p>
                        
                    </div>

                    <div class="about-desc-more text-left">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="about-desc-info">
                                    <b>Age : </b>
                                    <span>25</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="about-desc-info">
                                    <b>Localisation : </b>
                                    <span>Clermont-Ferrand (63)</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="about-desc-info">
                                    <b>Diplôme : </b>
                                    <span>BAC +3 (Licence pro web)</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="about-desc-info">
                                    <b>Malt : </b>
                                    <a class="text-underline" target="_blank" href="https://www.malt.fr/profile/ludovicrabat">Ludovic Rabat</a>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="about-desc-info">
                                    <b>Téléphone : </b>
                                    <a class="text-underline" href="tel:+33695948041">+33695948041</a>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="about-desc-info">
                                    <b>Email :</b>
                                    <a class="text-underline" href="mailto:ludo.rabat@gmail.com">ludo.rabat@gmail.com</a>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</section>
<!-- END: ABOUT -->

<!-- START: GOOGLE MAP -->
<section class="section pt-4 section-freelance section-light">
    <div class="container">
        <div class="section-head">
            <h2>Où suis-je ?</h2>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <agm-map [latitude]="lat" [longitude]="lng" [zoom]="15">
                    <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                  </agm-map>
                <div class="contact-map">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END: GOOGLE MAP -->

<!-- START: CTA -->
<section class="section pt-4 section-freelance section-light text-center ">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section-head wow-none fadeInUp" data-wow-none-duration="1.5s">
                    <h2>Un projet qui vous tient à coeur ?</h2>
                </div>
                <div class="mt-5 wow-none fadeInUp" data-wow-none-duration="1.5s" data-wow-none-delay=".2s">
                    <a routerLink="/contact" class="btn ">Parlons-en !</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END: CTA -->